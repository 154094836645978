import './App.css';
import Header from './components/Header'
import Article from './components/Article'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import List from './components/List';
import Login from './components/Login';
import ErrorPage from './components/ErrorPage';

// Header 태그를 쓸 수 있음
// 각 path에 맞게 해당 내용이 출력. 라우터는 실제 페이지가 이동하는 것이 아님. 마치 화면을 이동하는 가상 돔이 있는 것
function App() {
  return (
    <div className="App">
      <Header title="안녕하세요" onTest={function(){alert(1)}}/>
        <BrowserRouter>
          <Routes>
              <Route path="/" element={<List/>}></Route>
              <Route path="/login" element={<Login header="고객"/>}></Route>
              <Route path="*" element={<ErrorPage/>}></Route>
          </Routes>
        </BrowserRouter>
      <Article/>
    </div>
  );
}

export default App;