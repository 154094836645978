interface Props{
    title : string;
    onTest : Function;
}

// 헤더라는 컴포넌트
const Header = function(prop:Props){ // 반드시 파라미터를 받아야 하는 함수
    return(
        <div>
          <header>
            <h1><a href="/" onClick={function(evt){
                evt.preventDefault(); // 이벤트를 없애버림
                prop.onTest();
            }}>{prop.title}</a></h1>
          </header>
        </div>
      )
}

export default Header;