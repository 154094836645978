import { useState } from "react";
import { useEffect } from "react";

interface Test{
    id: string;
    name: string;
}

const List = ()=>{
    const [cnt, setCnt] = useState(0);
    const [list, setList] = useState<Test[]>([]);
    // useEffect는 한 번만 불러옴
    useEffect(()=>{
        fetch('http://localhost/react/list')
        .then((res)=>res.json())
        .then(result=>{
            setList(result);
        })
        console.log(1);
    }, []);
    return(
        <div>
            <ul>{list.map(item=>(
                <li key={item.id}>
                    {item.name}
                </li>
            ))}</ul>
            <span onClick={()=>setCnt(cnt+1)}>{cnt}</span>
        </div>
    )
}
export default List;