interface Props{
    header:string;
}

// 로그인이라는 컴포넌트
const Login = function(props:Props){ // 반드시 파라미터를 받아야 하는 함수
    const idLabel:string = '아이디';
    const pwdLabel:string = '비밀번호';
    const btnLabel:string = '로그인';
    const btnClick = function():void{
        alert(`${btnLabel}을 클릭했군요`);
    }
    // return 함수에 감싸인 html 처럼 보이지만 JSX(JavaScript XML)이다. 변수를 사용할 수 있다.
    return (
        <div>
            <h3>{props.header}</h3>
            <input type="text" id="id" placeholder={idLabel}/><br/>
            <input type="password" id="pwd" placeholder={pwdLabel}/>
            <button onClick={btnClick}>{btnLabel}</button>
        </div>
    )
}

export default Login;