const Article = function(){
    return(
        <div>
          <article>
            <h2>Welcome</h2>
            Hello, WEB
            </article>
        </div>
      )
}

export default Article;